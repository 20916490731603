import useDelete from 'src/hooks/useDelete';
import useFetch from 'src/hooks/useFetch';
import usePost from 'src/hooks/usePost';
import {
  CreatePayAdvance,
  IDelete,
  IPayAdvanceFilters,
  IPayAdvanceResponse,
  IPost,
  IPostResponse,
  IResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

export const PostPayAdvance = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, CreatePayAdvance> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    CreatePayAdvance
  >({
    key: 'post-pay-advance',
    url: '/payAdvance',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const FetchPayAdvance = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IPayAdvanceFilters,
): IResponse<IPayAdvanceResponse> => {
  const { isLoading, isFetching, error, data, refetch } = useFetch<IPayAdvanceResponse>({
    key: 'fetch-pay-advance',
    url: `/payAdvance?startdate=${filters.startdate}&finaldate=${filters.finaldate}&motoboyId=${filters.motoboyId}&page=${filters.page}&limit=${filters.limit}`,
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, isFetching, error, data, refetch };
};

export const DeletePayAdvance = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  payAdvanceId: number,
): IDelete<TDefaultResponse> => {
  const { isLoading, isError, error, isSuccess, refetch } = useDelete<TDefaultResponse>({
    key: 'deletePayAdvance',
    url: `/payAdvance/${payAdvanceId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
