import React, { useState } from 'react';
import styled from 'styled-components';

interface IModal {
  isVisible: boolean;
}

// Estilo do container principal
const Container = styled.div`
  position: relative;
  display: inline-block;
`;

// Estilo da Modal
const Modal = styled.div<IModal>`
  position: absolute;
  top: 100%;
  right: 0%;
  transform: translateX(0%);
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 10;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
}

// Componente principal
const HoverModal = ({ children, content }: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleMouseEnter = () => {
    setModalVisible(true);
  };

  const handleMouseLeave = () => {
    setModalVisible(false);
  };

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      <Modal isVisible={isModalVisible}>{content}</Modal>
    </Container>
  );
};

export default HoverModal;
