import styled from 'styled-components';
import Button from 'src/components/Button';
import Select from 'react-select';
import { Flex } from 'src/shared/styles';

export const ButtonCard = styled(Flex)`
  margin: 25px 8px 0 0;
`;

export const ExportButton = styled(Button)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  font-size: 12px;
  width: 100px;
`;

export const ExportCard = styled(Flex)`
  /* margin: 25px 8px 0 0; */
  /* background-color: ${({ theme }) => theme.colors.background}; */
  padding: 5px;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
`;

export const ValueFromSelect = styled(Select).attrs({
  margin: '25px 8px 0 0',
  width: '280px',
  height: '30px',
  fontSize: '14px',
  label: 'valor',
})``;

export const ExportColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExportMessage = styled.label`
  font-size: 9px !important;
  text-align: center;
  color: #fff;
`;

interface IStatusButton {
  active: boolean;
}

export const StatusButton = styled.button<IStatusButton>`
  padding: 8px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.secondary : theme.colors.disabled};
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.textLight)};
  border-radius: 15px;
  cursor: pointer;
  border: none;
`;

export const MoreIcon = styled.div`
  margin: 25px 8px 0 0;
  fontsize: 14px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  width: 30px;
  text-align: center;
`;
