import { Button, Container, Text } from './styles';
import {
  FaBriefcase,
  FaHome,
  FaMotorcycle,
  FaUserCircle,
  FaUsers,
  FaUserClock,
  FaFileInvoice,
  FaMoneyBillAlt,
} from 'react-icons/fa';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface Props {
  open: boolean;
}

const Menu: React.FC<Props> = ({ open }) => {
  const { colors } = useTheme();
  const { pathname } = useLocation();

  return (
    <Container open={open}>
      <Button selected={pathname === '/'} open={open} to="/" title="Início">
        <FaHome color={pathname === '/' ? colors.white : colors.textLight} size={30} />
        <Text selected={pathname === '/'} open={open}>
          INÍCIO
        </Text>
      </Button>
      <Button
        selected={pathname === '/MyData'}
        open={open}
        to="/MyData"
        title="Meus dados"
      >
        <FaUserCircle
          color={pathname === '/MyData' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/MyData'} open={open}>
          MEUS DADOS
        </Text>
      </Button>
      <Button
        selected={pathname === '/Deliveries'}
        open={open}
        to="/Deliveries"
        title="Relatório entregas (cliente)"
      >
        <FaBriefcase
          color={pathname === '/Deliveries' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/Deliveries'} open={open}>
          ENTREGAS
        </Text>
      </Button>
      <Button
        selected={pathname === '/Deliverymen'}
        open={open}
        to="/Deliverymen"
        title="Motoqueiros"
      >
        <FaMotorcycle
          color={pathname === '/Deliverymen' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/Deliverymen'} open={open}>
          MOTOQUEIROS
        </Text>
      </Button>
      <Button
        selected={pathname === '/Customer'}
        open={open}
        to="/Customer"
        title="Clientes"
      >
        <FaUsers
          color={pathname === '/Customer' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/Customer'} open={open}>
          CLIENTES
        </Text>
      </Button>
      <Button
        selected={pathname === '/MotoboyDeliveries'}
        open={open}
        to="/MotoboyDeliveries"
        title="Relatório entregas (motoboy)"
      >
        <FaUserClock
          color={pathname === '/MotoboyDeliveries' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/MotoboyDeliveries'} open={open}>
          RELATÓRIO ENTREGAS (MOTOBOY)
        </Text>
      </Button>
      <Button
        selected={pathname === '/Invoices'}
        open={open}
        to="/Invoices"
        title="Faturas"
      >
        <FaFileInvoice
          color={pathname === '/Invoices' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/Invoices'} open={open}>
          FATURAS
        </Text>
      </Button>
      <Button
        selected={pathname === '/PayAdvance'}
        open={open}
        to="/PayAdvance"
        title="Adiantamentos"
      >
        <FaMoneyBillAlt
          color={pathname === '/PayAdvance' ? colors.white : colors.textLight}
          size={30}
        />
        <Text selected={pathname === '/PayAdvance'} open={open}>
          Adiantamentos
        </Text>
      </Button>
    </Container>
  );
};

export default Menu;
