import React from 'react';
import { FaMotorcycle } from 'react-icons/fa';
import ConfirmationModal, {
  TConfirmationModalProps,
} from 'src/components/ConfirmationModal';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import MainContainer from 'src/components/MainContainer';
import MotoboySelect from 'src/components/MotoboySelect';
import PayAdvanceTable from 'src/components/PayAdvanceTable';
import { useSession } from 'src/hooks/useSession';
import { FetchDeliverymen } from 'src/services/deliverymen';
import { DeletePayAdvance, FetchPayAdvance } from 'src/services/pay-advance';
import {
  Container,
  Customer,
  EndDateInput,
  FilterContainer,
  Flex,
  Grid,
  Label,
  MotoboyInput,
  StartDateInput,
} from 'src/shared/styles';
import { IPayAdvanceData, IPayAdvanceFilters } from 'src/types';
import { dateFormatBR, formatDateStr } from 'src/utils/DateFormatter';
import { useTheme } from 'styled-components';

const PayAdvance = (): JSX.Element => {
  const date = new Date();

  const { session, refreshSession } = useSession();

  const [filterHeight, setFilterHeight] = React.useState<number>();

  const [deletePayAdvanceId, setDeletePayAdvanceId] = React.useState(0);

  const [confirmationModal, setConfirmationModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [infoModal, setInfoModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [filters, setFilters] = React.useState<IPayAdvanceFilters>({
    motoboyId: -1,
    startdate: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(
      'pt-BR',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
    ),
    finaldate: date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    limit: 10,
    page: 1,
  });

  const [payAdvances, setPayAdvances] = React.useState<IPayAdvanceData[]>([]);

  const { refetch: fetchPayAdvance } = FetchPayAdvance(
    session.token,
    refreshSession,
    filters,
  );

  const { data: motoboyList } = FetchDeliverymen(session.token, refreshSession);

  const { refetch: deletePayAdvance } = DeletePayAdvance(
    session.token,
    refreshSession,
    deletePayAdvanceId,
  );

  React.useEffect(() => {
    const height = document.getElementById('filters')?.clientHeight;

    setFilterHeight(height);

    fetchPayAdvance?.().then((response) => {
      if (response) {
        setPayAdvances(response.data?.data?.data ?? []);
      }
    });
  }, [filters, fetchPayAdvance]);

  const onChangeValue = React.useCallback((key: string, value: string | number) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const onChangeStartDate = (value: string) => {
    const initialDate = dateFormatBR(value);
    setFilters({ ...filters, startdate: initialDate });
  };

  const onChangeFinalDate = (value: string) => {
    const finalDate = dateFormatBR(value);
    setFilters({ ...filters, finaldate: finalDate });
  };

  const onDeletePayAdvance = React.useCallback(async () => {
    try {
      setConfirmationModal({ show: false, message: '', header: '' });
      const response = await deletePayAdvance();
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Adiantamento excluído com sucesso',
          show: true,
        });

        const refetchedResponse = await fetchPayAdvance?.();
        if (refetchedResponse) {
          setPayAdvances(refetchedResponse.data?.data?.data ?? []);
        }
      }
    } catch (error: any) {
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [deletePayAdvance]);

  const showOnDeleteConfirmation = (payAdvanceId: number) => {
    setDeletePayAdvanceId(payAdvanceId);

    setConfirmationModal({
      message: 'Excluir adiantamento',
      header: 'Deseja excluir esse adiantamento?',
      show: true,
    });
  };

  return (
    <MainContainer>
      <Container>
        <FilterContainer id="filters">
          <Grid>
            <Label>Filtros</Label>
            <MotoboySelect
              width="340px"
              margin="10px 10px 0 0"
              motoboyList={motoboyList?.data?.data || []}
              onSelect={(motoboy) => onChangeValue('motoboyId', motoboy.motoboyId)}
              showAll
            />
          </Grid>
          <Flex>
            <Grid>
              <Label>Data inicial</Label>
              <StartDateInput
                text={formatDateStr(filters.startdate)}
                onChangeValue={onChangeStartDate}
              />
            </Grid>
            <Grid>
              <Label>Data final</Label>
              <EndDateInput
                text={formatDateStr(filters.finaldate)}
                onChangeValue={onChangeFinalDate}
              />
            </Grid>
          </Flex>
        </FilterContainer>
        <PayAdvanceTable
          filterHeight={filterHeight ?? 0}
          isLoading={false}
          onChangePage={(page) => {
            console.log(page);
          }}
          onDelete={showOnDeleteConfirmation}
          onPayPayAdvance={(payAdvance) => console.log(payAdvance)}
          onShowDetails={(payAdvance) => console.log(payAdvance)}
          onShowPayAdvance={(payAdvance) => console.log(payAdvance)}
          totalRows={0}
          payAdvances={payAdvances}
        />
        <InformationModal
          headerText={infoModal.headerText}
          show={infoModal.show}
          onDismiss={() => setInfoModal({ headerText: '', message: '', show: false })}
          message={infoModal.message}
        />
        <ConfirmationModal
          show={confirmationModal.show}
          header={confirmationModal.header}
          message={confirmationModal.message}
          onCancel={() => setConfirmationModal({ show: false, header: '', message: '' })}
          onConfirm={onDeletePayAdvance}
        />
      </Container>
    </MainContainer>
  );
};

export default PayAdvance;
