import {
  Row,
  MotoboyIcon,
  MotoboyName,
  ActionsContainer,
  EmptyContainer,
  EmptyMessage,
  ButtonIcon,
  TotalDeliveries,
  DeliveriesIcon,
  CashIcon,
} from './styles';

import DataTable, { createTheme } from 'react-data-table-component';
import { IFetchPendencies, IMotoboyDeliveries } from 'src/types';
import Loader from 'src/components/Loader';
import React from 'react';
import { useTheme } from 'styled-components';
import { maskToPrice } from 'src/utils/InputMask';
import { HistoryIcon, PendencyIcon } from '../Icon';
import { FaEye, FaMoneyBillWaveAlt } from 'react-icons/fa';

interface Props {
  motoboys?: IMotoboyDeliveries[];
  totalRows: number | undefined;
  isLoading: boolean;
  onChangePage(page: number): void;
  onShowDetails(motoboyDeliveries: IMotoboyDeliveries): void;
  registerPendency(motoboyId: number): void;
  viewPendencies(pendencies: IFetchPendencies[]): void;
  filtersHeight: number;
  registerPayAdvance(motoboyId: number): void;
}

const DeliveriesTable: React.FC<Props> = ({
  motoboys,
  isLoading,
  totalRows,
  onShowDetails,
  onChangePage,
  registerPendency,
  viewPendencies,
  filtersHeight,
  registerPayAdvance,
}) => {
  const { colors } = useTheme();

  createTheme('jucaTheme', {
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(0,0,0,.12)',
    },
    background: {
      default: '#424242',
    },
    context: {
      background: '#E91E63',
      text: '#FFFFFF',
    },
    divider: {
      default: 'transparent',
    },
    button: {
      default: '#FFFFFF',
      focus: 'rgba(255, 255, 255, .54)',
      hover: 'rgba(255, 255, 255, .12)',
      disabled: 'rgba(255, 255, 255, .18)',
    },
    selected: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    highlightOnHover: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    striped: {
      default: 'rgba(0, 0, 0, .87)',
      text: '#FFFFFF',
    },
  });

  const columns = React.useMemo(
    () => [
      {
        name: 'Nome',
        cell: ({ name }: IMotoboyDeliveries) => (
          <Row>
            <MotoboyIcon />
            <MotoboyName>{name}</MotoboyName>
          </Row>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Total de corridas',
        selector: 'description',
        cell: ({ total_deliveries }: IMotoboyDeliveries) => (
          <Row>
            <DeliveriesIcon />
            <TotalDeliveries>Corridas: {total_deliveries}</TotalDeliveries>
          </Row>
        ),
      },
      {
        name: 'Valor Total',
        selector: 'date',
        cell: ({ total_value }: IMotoboyDeliveries) => (
          <Row>
            <CashIcon>R$</CashIcon>
            <TotalDeliveries>
              {total_value.toString() === '0.00'
                ? '0,00'
                : maskToPrice(total_value.toString())}
            </TotalDeliveries>
          </Row>
        ),
      },
      {
        name: 'Funções',
        cell: (motoboyDeliveries: IMotoboyDeliveries) => (
          <ActionsContainer>
            {motoboyDeliveries.receipts.length > 0 && (
              <ButtonIcon
                onClick={() => {
                  onShowDetails(motoboyDeliveries);
                }}
              >
                <HistoryIcon color={colors.white} width={20} height={20} />
              </ButtonIcon>
            )}
            <ButtonIcon
              onClick={() => registerPendency(motoboyDeliveries.motoboyId)}
              title="Registrar pendências"
            >
              <PendencyIcon color={colors.white} width={20} height={20} />
            </ButtonIcon>
            {motoboyDeliveries.pendencies?.length > 0 && (
              <ButtonIcon
                title="Ver pendências"
                onClick={() => viewPendencies(motoboyDeliveries.pendencies)}
              >
                <FaEye color={colors.white} width={20} height={20} size={20} />
              </ButtonIcon>
            )}
            <ButtonIcon
              onClick={() => registerPayAdvance(motoboyDeliveries.motoboyId)}
              title="Registrar adiantamento"
            >
              <FaMoneyBillWaveAlt color={colors.white} width={20} height={20} size={20} />
            </ButtonIcon>
          </ActionsContainer>
        ),
        ignoreRowClick: true,
        button: true,
      },
    ],
    [colors.white, onShowDetails, registerPendency, viewPendencies],
  );

  const customStyle = React.useMemo(
    () => ({
      table: {
        style: {
          padding: '0 10px 0',
          backgroundColor: colors.mainBackground,
          color: colors.text,
        },
      },
      rows: {
        style: {
          fontSize: '13px',
          color: colors.text,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
          minHeight: '60px',
          margin: '8px 0 8px',
          borderRadius: '10px',
        },
      },
      pagination: {
        style: {
          backgroundColor: colors.mainBackground,
          color: colors.white,
        },
      },
      columns: {
        style: {
          minWidth: '160px',
        },
      },
    }),
    [colors],
  );

  return (
    <DataTable
      subHeader={false}
      noHeader
      noTableHead
      responsive
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={(page) => {
        onChangePage(page);
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: `calc(100% - ${filtersHeight + 60}px)`,
        overflow: 'auto',
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Quantidade de linhas:',
        rangeSeparatorText: 'Total de Registros:',
        noRowsPerPage: true,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      }}
      columns={columns}
      data={motoboys == null ? [] : motoboys}
      theme="jucaTheme"
      customStyles={customStyle}
      noDataComponent={
        <EmptyContainer>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <EmptyMessage>Lista vazia</EmptyMessage>
          )}
        </EmptyContainer>
      }
    />
  );
};

export default DeliveriesTable;
