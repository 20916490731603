import styled, { css } from 'styled-components';
import Button from 'src/components/Button';
import { FaCalendar, FaExclamationCircle } from 'react-icons/fa';
import Input from '../Input';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding: 20px 20px 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;
export const DismissButton = styled(Button)`
  width: 100px;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.textError};
  border: 2px solid ${({ theme }) => theme.colors.textError};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 49px;
`;

export const RegisterButton = styled(Button)`
  width: 100px;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.approve};
  border: 2px solid ${({ theme }) => theme.colors.approve};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 49px;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width: 50%;
  max-width: 800px;
  min-width: 400px;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const Content = styled.div`
  padding: 10px;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ErrorIcon = styled(FaExclamationCircle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.text};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

interface InputError {
  error: boolean;
}

export const DateInput = styled(Input).attrs({
  placeholder: 'data',
  id: 'date',
  type: 'date',
  margin: '10px 10px 0 0',
  width: '100%',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;

export const IconDate = styled(FaCalendar)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin-right: 8px;
`;

export const Label = styled.div`
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const DescriptionInput = styled.textarea`
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.textLight};
  min-height: 135px;
  width: 100%;
  padding: 10px;
  resize: none;
`;

export const CostInput = styled(Input).attrs({
  placeholder: 'Valor',
  id: 'cost',
  type: 'text',
  margin: '10px 0 0 0',
  width: '100%',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 5px;
`;
