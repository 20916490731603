import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { EmptyContainer, EmptyMessage } from 'src/shared/styles';
import { IPayAdvance, IPayAdvanceData } from 'src/types';
import { useTheme } from 'styled-components';
import Loader from '../Loader';
import {
  ActionsContainer,
  DeleteButtonIcon,
  DescriptionContainer,
  TrashIcon,
} from '../DeliveriesTable/styles';
import { maskToPrice } from 'src/utils/InputMask';
import { dateTimeFormat } from 'src/utils/DateFormatter';

interface Props {
  payAdvances?: IPayAdvanceData[];
  totalRows: number | undefined;
  isLoading: boolean;
  onChangePage(page: number): void;
  onShowPayAdvance(payAdvance: IPayAdvance): void;
  onDelete(payAdvanceId: number): void;
  onShowDetails(payAdvance: IPayAdvance): void;
  onPayPayAdvance(payAdvance: IPayAdvance): void;
  filterHeight: number;
}

const PayAdvanceTable: React.FC<Props> = ({
  payAdvances,
  isLoading,
  totalRows,
  onChangePage,
  onShowPayAdvance,
  onShowDetails,
  onDelete,
  onPayPayAdvance,
  filterHeight,
}) => {
  const { colors } = useTheme();

  createTheme('jucaTheme', {
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(0,0,0,.12)',
    },
    background: {
      default: '#424242',
    },
    context: {
      background: '#E91E63',
      text: '#FFFFFF',
    },
    divider: {
      default: 'transparent',
    },
    button: {
      default: '#FFFFFF',
      focus: 'rgba(255, 255, 255, .54)',
      hover: 'rgba(255, 255, 255, .12)',
      disabled: 'rgba(255, 255, 255, .18)',
    },
    selected: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    highlightOnHover: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    striped: {
      default: 'rgba(0, 0, 0, .87)',
      text: '#FFFFFF',
    },
  });

  const columns = React.useMemo(
    () => [
      {
        name: 'Descrição',
        selector: 'description',
        cell: ({ description }: IPayAdvanceData) => (
          <DescriptionContainer color={colors.text}>{description}</DescriptionContainer>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Data',
        selector: 'date',
        cell: ({ date }: IPayAdvanceData) => (
          <DescriptionContainer color={colors.text}>
            {dateTimeFormat(new Date(date))}
          </DescriptionContainer>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Valor',
        selector: 'value',
        cell: ({ value }: IPayAdvanceData) => (
          <DescriptionContainer color={colors.text}>
            R$ {maskToPrice(Number(value).toFixed(2).toString()).replace('.', ',')}
          </DescriptionContainer>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Motoboy',
        selector: 'value',
        cell: ({ motoboy }: IPayAdvanceData) => (
          <DescriptionContainer color={colors.text}>{motoboy.name}</DescriptionContainer>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Funções',
        cell: (payAdvance: IPayAdvanceData) => (
          <ActionsContainer>
            <DeleteButtonIcon onClick={() => onDelete(payAdvance.payAdvanceId)}>
              <TrashIcon color={colors.white} />
            </DeleteButtonIcon>
          </ActionsContainer>
        ),
        ignoreRowClick: true,
        button: true,
      },
    ],
    [],
  );

  const customStyle = React.useMemo(
    () => ({
      table: {
        style: {
          padding: '0 10px 0',
          backgroundColor: colors.mainBackground,
          color: colors.text,
          overflow: 'auto',
          maxWidth: '100%',
        },
      },
      rows: {
        style: {
          fontSize: '13px',
          color: colors.text,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
          minHeight: '60px',
          margin: '8px 0 8px',
          borderRadius: '10px',
        },
      },
      pagination: {
        style: {
          backgroundColor: colors.mainBackground,
          color: colors.white,
        },
      },
      columns: {
        style: {
          minWidth: '160px',
          padding: '10px',
        },
      },
    }),
    [colors],
  );

  return (
    <DataTable
      subHeader={false}
      noHeader
      noTableHead
      responsive
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={(page) => {
        onChangePage(page);
      }}
      style={{
        alignItems: payAdvances && payAdvances.length > 0 ? 'flex-start' : 'center',
        justifyContent: 'center',
        height: `calc(100% - ${filterHeight + 60}px)`,
        overflow: 'auto',
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Quantidade de linhas:',
        rangeSeparatorText: 'Total de Registros:',
        noRowsPerPage: true,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      }}
      columns={columns}
      data={payAdvances == null ? [] : payAdvances}
      theme="jucaTheme"
      customStyles={customStyle}
      noDataComponent={
        <EmptyContainer>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <EmptyMessage>Lista vazia</EmptyMessage>
          )}
        </EmptyContainer>
      }
    />
  );
};

export default PayAdvanceTable;
