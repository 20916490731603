import {
  Backdrop,
  Body,
  ClientContainer,
  CloseButton,
  CloseIcon,
  Column,
  Container,
  Content,
  CostInput,
  CurrencyContainer,
  DateInput,
  DescriptionInput,
  Header,
  HeaderText,
  IconDate,
  IconMotoboy,
  IconMotoboyInfo,
  Line,
  ModalContainer,
  MotoboyInput,
  PaymentInfo,
  PaymentLabel,
  PersonalInfoContainer,
  PersonalInfoMessage,
  ReceiptInfo,
  SaveButton,
  StyledModal,
  Label,
} from './styles';

import { ICustomer, IReceipts } from 'src/types';
import PaymentType from '../PaymentType';
import React from 'react';
import ReactDOM from 'react-dom';
import { isEmpty } from 'lodash';
import { maskToPrice } from 'src/utils/InputMask';
import CustomerSelect from 'src/components/CustomerSelect';
import { dateInputFormat } from 'src/utils/DateFormatter';

export interface ShowReceiptModal {
  show: boolean;
  receipt: IReceipts;
}

export interface ShowCreateModal {
  show: boolean;
  receipt?: IReceipts;
}

interface Props {
  receipt?: IReceipts;
  show: boolean;
  onDismiss(): void;
  onEdit(receipt: IReceipts): void;
  customerList: ICustomer[];
}

const DeliveryModal: React.FC<Props> = ({
  receipt,
  show,
  onDismiss,
  onEdit,
  customerList,
}) => {
  const [editReceipt, setEditReceipt] = React.useState<IReceipts>({} as IReceipts);
  const maxLength = 150;
  const today = new Date();
  const maxDate = dateInputFormat(today);

  React.useEffect(() => {
    if (receipt) {
      setEditReceipt(receipt);
    }
  }, [receipt]);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const formatDate = (timestamp: number) => {
    let dateFormat = new Date(timestamp).toLocaleDateString('en-za');
    dateFormat = dateFormat.replace(/[/]/g, '-');

    return dateFormat;
  };

  const onChange = React.useCallback((key: keyof IReceipts, value: any) => {
    setEditReceipt((prev) => ({ ...prev, [key]: value }));
  }, []);

  const modal = !isEmpty(editReceipt) && (
    <Container>
      <Backdrop onClick={onDismiss} />
      <ModalContainer aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>
              <IconMotoboy />
              EDITAR CORRIDA
            </HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Body>
              <ClientContainer>
                <IconMotoboyInfo />
                <Column>
                  <CustomerSelect
                    defaultCustomer={customerList.find(
                      (e) => e.customerId === editReceipt.customer.customerId,
                    )}
                    onSelect={(customer) => onChange('customer', customer)}
                    customerList={customerList}
                  />
                  <Line>
                    <MotoboyInput
                      error={false}
                      text={editReceipt?.motoboy?.name}
                      value={editReceipt?.motoboy?.name}
                      icon={<IconMotoboy />}
                      disabled
                    />
                    <DateInput
                      error={false}
                      text={formatDate(editReceipt?.date)}
                      value={formatDate(editReceipt?.date)}
                      icon={<IconDate />}
                      onChangeValue={(date) => {
                        let value = new Date(date).getTime();
                        value += 3 * 60 * 60 * 1000;
                        onChange('date', value);
                      }}
                      max={maxDate}
                    />
                  </Line>
                </Column>
              </ClientContainer>
              <PersonalInfoContainer>
                <PersonalInfoMessage>INFORMAÇÕES ADICIONAIS</PersonalInfoMessage>
                <ReceiptInfo>
                  <Column>
                    <DescriptionInput
                      value={editReceipt?.description}
                      onChange={(e) => onChange('description', e.target.value)}
                      maxLength={maxLength}
                    />
                    <Label>
                      {editReceipt?.description.length}/{maxLength}
                    </Label>
                  </Column>
                  <Column>
                    <Column>
                      <PaymentLabel>Tipo de pagamento</PaymentLabel>
                      <PaymentInfo>
                        <PaymentType
                          value="billed"
                          disabled={editReceipt.paymentType !== 'billed'}
                          onChange={(value) => onChange('paymentType', value)}
                        />
                        <PaymentType
                          value="money"
                          disabled={editReceipt.paymentType !== 'money'}
                          onChange={(value) => onChange('paymentType', value)}
                        />
                        <PaymentType
                          value="pix"
                          disabled={editReceipt.paymentType !== 'pix'}
                          onChange={(value) => onChange('paymentType', value)}
                        />
                      </PaymentInfo>
                    </Column>
                    <CostInput
                      icon={<CurrencyContainer>R$</CurrencyContainer>}
                      text={editReceipt.cost.toString().replace('.', ',')}
                      error={
                        editReceipt.cost === null || parseFloat(editReceipt.cost) === 0
                      }
                      onChangeValue={(value) => onChange('cost', maskToPrice(value))}
                    />
                  </Column>
                </ReceiptInfo>
              </PersonalInfoContainer>
              <Line>
                <SaveButton onClick={() => onEdit(editReceipt)}>SALVAR</SaveButton>
              </Line>
            </Body>
          </Content>
        </StyledModal>
      </ModalContainer>
    </Container>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default DeliveryModal;
