import React from 'react';
import ReactDOM from 'react-dom';
import {
  Backdrop,
  Body,
  ClientContainer,
  CloseButton,
  CloseIcon,
  Column,
  Container,
  Content,
  CostInput,
  CurrencyContainer,
  DateInput,
  DescriptionInput,
  Header,
  HeaderText,
  IconDate,
  IconMotoboy,
  IconMotoboyInfo,
  Line,
  ModalContainer,
  PaymentInfo,
  PaymentLabel,
  PersonalInfoContainer,
  PersonalInfoMessage,
  ReceiptInfo,
  SaveButton,
  StyledModal,
  Label,
} from './styles';
import { ICustomer, IDeliveryman, TPayment } from 'src/types';
import CustomerSelect from '../CustomerSelect';
import { dateInputFormat } from 'src/utils/DateFormatter';
import MotoboySelect from '../MotoboySelect';
import PaymentType from '../PaymentType';
import { maskToPrice } from 'src/utils/InputMask';
import Loader from '../Loader';

const maxLength = 150;

export interface CreateReceipt {
  description: string;
  cost: string;
  paymentType: TPayment;
  employee?: string;
  employeeRegister?: string;
  customerId: number;
  motoboyId: number;
  date: number;
}

interface CreateReceiptModalProps {
  show: boolean;
  onDismiss(): void;
  onCreate(receipt: CreateReceipt): void; // New prop
  customerList: ICustomer[];
  motoboyList: IDeliveryman[];
  loading: boolean;
}

const CreateDeliveryModal: React.FC<CreateReceiptModalProps> = ({
  // New Component Name
  show,
  onDismiss,
  onCreate, // New prop
  customerList,
  motoboyList,
  loading,
}) => {
  const [newReceipt, setNewReceipt] = React.useState<CreateReceipt>({
    cost: '0',
    customerId: -1,
    description: '',
    motoboyId: -1,
    paymentType: '',
    employee: '',
    employeeRegister: '',
    date: new Date().getTime(),
  });

  const today = new Date();
  const maxDate = dateInputFormat(today);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const formatDate = (timestamp: number) => {
    let dateFormat = new Date(timestamp).toLocaleDateString('en-za');
    dateFormat = dateFormat.replace(/[/]/g, '-');
    return dateFormat;
  };

  const onChange = React.useCallback((key: keyof CreateReceipt, value: any) => {
    setNewReceipt((prev) => ({ ...prev, [key]: value }));
  }, []);

  const modal = // No need for the isEmpty check here
    (
      <Container>
        <Backdrop onClick={onDismiss} />
        <ModalContainer aria-modal tabIndex={-1} role="dialog">
          <StyledModal>
            <Header>
              <HeaderText>
                <IconMotoboy />
                CRIAR CORRIDA {/* Changed Text */}
              </HeaderText>
              <CloseButton onClick={onDismiss}>
                <CloseIcon />
              </CloseButton>
            </Header>
            <Content>
              {loading ? (
                <Body>
                  <Loader />
                </Body>
              ) : (
                <Body>
                  <ClientContainer>
                    <IconMotoboyInfo />
                    <Column>
                      <CustomerSelect
                        onSelect={(customer) =>
                          onChange('customerId', customer.customerId)
                        }
                        customerList={customerList}
                      />
                      <Line>
                        <MotoboySelect
                          width="50%"
                          margin="10px 10px 0 0"
                          motoboyList={motoboyList}
                          onSelect={(motoboy) => onChange('motoboyId', motoboy.motoboyId)}
                        />
                        <DateInput
                          error={false}
                          text={formatDate(newReceipt.date)}
                          value={formatDate(newReceipt.date)}
                          icon={<IconDate />}
                          onChangeValue={(date) => {
                            let value = new Date(date).getTime();
                            value += 3 * 60 * 60 * 1000;
                            onChange('date', value);
                          }}
                          max={maxDate}
                        />
                      </Line>
                    </Column>
                  </ClientContainer>
                  <PersonalInfoContainer>
                    <PersonalInfoMessage>INFORMAÇÕES ADICIONAIS</PersonalInfoMessage>
                    <ReceiptInfo>
                      <Column>
                        <DescriptionInput
                          value={newReceipt?.description}
                          onChange={(e) => onChange('description', e.target.value)}
                          maxLength={maxLength}
                        />
                        <Label>
                          {newReceipt?.description.length}/{maxLength}
                        </Label>
                      </Column>
                      <Column>
                        <Column>
                          <PaymentLabel>Tipo de pagamento</PaymentLabel>
                          <PaymentInfo>
                            <PaymentType
                              value="billed"
                              disabled={newReceipt.paymentType !== 'billed'}
                              onChange={(value) => onChange('paymentType', value)}
                            />
                            <PaymentType
                              value="money"
                              disabled={newReceipt.paymentType !== 'money'}
                              onChange={(value) => onChange('paymentType', value)}
                            />
                            <PaymentType
                              value="pix"
                              disabled={newReceipt.paymentType !== 'pix'}
                              onChange={(value) => onChange('paymentType', value)}
                            />
                          </PaymentInfo>
                        </Column>
                        <CostInput
                          icon={<CurrencyContainer>R$</CurrencyContainer>}
                          text={newReceipt.cost.toString().replace('.', ',')}
                          error={
                            newReceipt.cost === null || parseFloat(newReceipt.cost) === 0
                          }
                          onChangeValue={(value) => onChange('cost', maskToPrice(value))}
                        />
                      </Column>
                    </ReceiptInfo>
                  </PersonalInfoContainer>
                  <Line>
                    <SaveButton onClick={() => onCreate(newReceipt)}>CRIAR</SaveButton>{' '}
                    {/* Changed Text */}
                  </Line>
                </Body>
              )}
            </Content>
          </StyledModal>
        </ModalContainer>
      </Container>
    );

  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default CreateDeliveryModal; // export the new component
