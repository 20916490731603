import {
  Backdrop,
  Body,
  Column,
  Container,
  Content,
  CostInput,
  CurrencyContainer,
  DateInput,
  DescriptionInput,
  DismissButton,
  ErrorIcon,
  Form,
  Header,
  HeaderText,
  IconDate,
  Label,
  Line,
  RegisterButton,
  StyledModal,
} from './styles';

import React from 'react';
import ReactDOM from 'react-dom';
import { IPayAdvance } from 'src/types';
import { dateInputFormat, formatDate } from 'src/utils/DateFormatter';
import { maskToPrice } from 'src/utils/InputMask';

const maxLength = 150;

export type TInformationModalProps = {
  show: boolean;
  headerText: string;
  message: string;
};

type TProps = {
  show: boolean;
  onCreate(data: IPayAdvance): void;
  onDismiss(): void;
};

const PayAdvanceModal: React.FC<TProps> = ({ show, onCreate, onDismiss }) => {
  const [newPayAdvance, setNewPayAdvance] = React.useState<IPayAdvance>({
    date: new Date().getTime(),
    description: '',
    value: '0',
  });

  const today = new Date();
  const maxDate = dateInputFormat(today);
  const firstDayOfMonth = dateInputFormat(
    new Date(today.getFullYear(), today.getMonth(), 1),
  );

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const onChange = React.useCallback((key: string, value: any) => {
    setNewPayAdvance((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleRegistarPay = React.useCallback(() => {
    onCreate(newPayAdvance);
    onDismiss();
  }, [newPayAdvance]);

  const modal = (
    <>
      <Backdrop onClick={onDismiss} />
      <Container
        aria-modal
        aria-labelledby="Registrar adiantamento"
        tabIndex={-1}
        role="dialog"
      >
        <StyledModal>
          <Header>
            <ErrorIcon />
            <HeaderText>Registrar adiantamento</HeaderText>
          </Header>
          <Content>
            <Body>
              <Form>
                <Column>
                  <Label>Data</Label>
                  <DateInput
                    error={false}
                    text={formatDate(newPayAdvance.date)}
                    value={formatDate(newPayAdvance.date)}
                    icon={<IconDate />}
                    onChangeValue={(date) => {
                      let value = new Date(date).getTime();
                      value += 3 * 60 * 60 * 1000;
                      onChange('date', value);
                    }}
                    max={maxDate}
                    min={firstDayOfMonth}
                  />
                </Column>
                <Column>
                  <Label>Valor</Label>
                  <CostInput
                    icon={<CurrencyContainer>R$</CurrencyContainer>}
                    text={newPayAdvance.value.toString().replace('.', ',')}
                    error={
                      newPayAdvance.value === null ||
                      parseFloat(newPayAdvance.value) === 0
                    }
                    onChangeValue={(value) => onChange('value', maskToPrice(value))}
                  />
                </Column>
                <Column>
                  <Label>Descrição</Label>
                  <DescriptionInput
                    value={newPayAdvance.description}
                    onChange={(e) => onChange('description', e.target.value)}
                    maxLength={maxLength}
                  />
                  <Label>
                    {newPayAdvance.description.length}/{maxLength}
                  </Label>
                </Column>
              </Form>
              <Line>
                <DismissButton onClick={onDismiss}>Cancelar</DismissButton>
                <RegisterButton onClick={handleRegistarPay}>Registrar</RegisterButton>
              </Line>
            </Body>
          </Content>
        </StyledModal>
      </Container>
    </>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default PayAdvanceModal;
