import {
  BarDetail,
  InvoicesCard,
  CardBody,
  CardBodyMultiple,
  CardFooter,
  CardTitle,
  CardTitleCenter,
  ChartCard,
  Cipher,
  SubTitleCard,
  ClientsCard,
  Column,
  DeliveriesCard,
  Flex,
  Grid,
  PayButtonIcon,
  PaymentCard,
  ClientCard,
  BoyCard,
  FieldName,
  FieldValue,
  FlexStart,
  DateInput,
} from './styles';

import BarGraph from 'src/components/BarGraph';
import { CoinIcon } from 'src/components/Icon';
import { FetchDashboard } from 'src/services/dashboard';
import InformationModal from 'src/components/InformationModal';
import Loader from 'src/components/Loader';
import MainContainer from 'src/components/MainContainer';
import React from 'react';
import { TDashboard, OverdueInvoice } from 'src/types';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';
import months from 'src/utils/Months';

interface IInfoModal {
  show: boolean;
  overdueInvoice: OverdueInvoice[];
}

const Dashboard = (): JSX.Element => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [modalMessage, setModalMessage] = React.useState<string>('');
  const [invoiceInfoModal, setInvoiceInfoModal] = React.useState<IInfoModal>({
    show: false,
    overdueInvoice: [],
  });
  const [dateFilter, setDateFilter] = React.useState<string>(
    new Date().toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  );

  const [dashboardParams, setDashboardParams] = React.useState<TDashboard>({
    totalClients: -1,
    totalMotoboys: -1,
    dailyBilling: -1,
    totalDeliveries: -1,
    monthlyBilling: -1,
    totalAppointmentsPerMonth: [
      { pos: 1, month: 'JAN', value: 0, total: 0 },
      { pos: 2, month: 'FEV', value: 0, total: 0 },
      { pos: 3, month: 'MAR', value: 0, total: 0 },
      { pos: 4, month: 'ABR', value: 0, total: 0 },
      { pos: 5, month: 'MAI', value: 0, total: 0 },
      { pos: 6, month: 'JUN', value: 0, total: 0 },
      { pos: 7, month: 'JUL', value: 0, total: 0 },
      { pos: 8, month: 'AGO', value: 0, total: 0 },
      { pos: 9, month: 'SET', value: 0, total: 0 },
      { pos: 10, month: 'OUT', value: 0, total: 0 },
      { pos: 11, month: 'NOV', value: 0, total: 0 },
      { pos: 12, month: 'DEZ', value: 0, total: 0 },
    ],
    overdueInvoices: [],
    totalOverdueInvoiceValue: 0,
  });
  const { colors } = useTheme();
  const { session, refreshSession } = useSession();
  const { data, isLoading, refetch, isFetching } = FetchDashboard(
    session?.token,
    dateFilter,
    refreshSession,
  );

  React.useEffect(() => {
    refetch?.();
  }, [dateFilter]);

  React.useEffect(() => {
    if (!data) return;
    const response = data?.data;
    if (
      !response ||
      response.dailyBilling === undefined ||
      response.monthlyBilling === undefined ||
      response.totalAppointmentsPerMonth === undefined ||
      !Array.isArray(response.totalAppointmentsPerMonth) ||
      response.totalClients === undefined ||
      response.totalDeliveries === undefined ||
      response.totalMotoboys === undefined ||
      response.totalOverdueInvoiceValue === undefined
    ) {
      setModalMessage('Ocorreu um erro ao carregar os dados');
      setShowModal(true);
      return;
    }

    setDashboardParams(response);
  }, [data]);

  const showDetailsInvoice = (overdueInvoice: OverdueInvoice[]) => {
    setInvoiceInfoModal({ overdueInvoice, show: true });
  };

  const closeDetailsInvoice = () => {
    setInvoiceInfoModal({ show: false, overdueInvoice: [] });
  };

  const getComponent = React.useCallback(
    (num: number, color: string): JSX.Element | number | string => {
      if (isLoading) {
        return <Loader color={color} />;
      }
      if (num === -1) {
        return '...';
      }
      return num;
    },
    [isLoading],
  );

  const formatDate = (dt: string) => {
    const parts = dt.split('/');

    const dateFormat = parts[2].concat('-').concat(parts[1]).concat('-').concat(parts[0]);

    return dateFormat;
  };

  return (
    <MainContainer>
      <Grid>
        <InvoicesCard>
          <BarDetail color={colors.textError} />
          <CardTitle>FATURAS ATRASADAS</CardTitle>
          <SubTitleCard style={{ textAlign: 'left' }}>
            Valor total :{' '}
            {dashboardParams.totalOverdueInvoiceValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </SubTitleCard>
          <Column>
            {isLoading && <Loader color={colors.primary} />}

            {!isLoading &&
              dashboardParams?.overdueInvoices?.map((overdueInvoice) => (
                <Flex>
                  <FieldName>{overdueInvoice.customer.name}</FieldName>
                  <FieldValue>
                    {overdueInvoice.value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </FieldValue>
                  <PayButtonIcon
                    onClick={() => showDetailsInvoice(overdueInvoice.overdueInvoice)}
                  >
                    Ver detalhes
                  </PayButtonIcon>
                </Flex>
              ))}

            {!isLoading && dashboardParams?.overdueInvoices?.length === 0 && (
              <Column style={{ justifyContent: 'center' }}>
                <SubTitleCard>SEM RESULTADOS</SubTitleCard>
              </Column>
            )}
          </Column>
          <CardFooter>Valores referentes aos meses anteriores</CardFooter>
        </InvoicesCard>
        <ChartCard>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <BarGraph
              data={dashboardParams.totalAppointmentsPerMonth}
              title="TOTAL DE ENTREGAS MENSAIS"
              footer="Valores referentes ao valor total computado das entregas em cada mês"
            />
          )}
        </ChartCard>
        <ClientsCard>
          <ClientCard>
            <SubTitleCard>CLIENTES CADASTRADOS</SubTitleCard>
            <CardBodyMultiple>
              {getComponent(dashboardParams.totalClients, colors.primary)}
            </CardBodyMultiple>
            {/* <CardFooter>
              Valores referentes a quantidade total de clientes no sistema
            </CardFooter> */}
          </ClientCard>
          <BoyCard>
            <SubTitleCard>MOTOBOYS CADASTRADOS</SubTitleCard>
            <CardBodyMultiple>
              {getComponent(dashboardParams.totalMotoboys, colors.primary)}
            </CardBodyMultiple>
            {/* <CardFooter>
              Valores referentes a quantidade total de motoboys no sistema
            </CardFooter> */}
          </BoyCard>
        </ClientsCard>
        <DeliveriesCard>
          <Column style={{ alignItems: 'flex-start' }}>
            <DateInput
              error={false}
              text={formatDate(dateFilter)}
              onChangeValue={(e) =>
                setDateFilter(new Date(e.concat(' 15:00:00')).toLocaleDateString('pt-BR'))
              }
            />
            <div style={{ width: '100%' }}>
              <CardTitleCenter>TOTAL DE ENTREGAS HOJE</CardTitleCenter>
            </div>
          </Column>
          <CardBody>
            {!isFetching ? (
              getComponent(dashboardParams.totalDeliveries, colors.primary)
            ) : (
              <Loader color={colors.primary} />
            )}
          </CardBody>
          <CardFooter>Valores referentes ao total diário de entregas</CardFooter>
        </DeliveriesCard>
        <PaymentCard>
          <CardTitle>FATURAMENTO</CardTitle>
          <Cipher>R$</Cipher>
          <CardBody>
            {getComponent(dashboardParams.monthlyBilling, colors.primary)}
            <CoinIcon />
          </CardBody>

          <CardFooter>Valores referentes ao faturamento total do mês</CardFooter>
        </PaymentCard>
      </Grid>

      <InformationModal
        headerText="Erro"
        show={showModal}
        onDismiss={() => setShowModal(false)}
        message={modalMessage}
      />
      <InformationModal
        headerText="Faturas em atraso"
        show={invoiceInfoModal.show}
        onDismiss={closeDetailsInvoice}
        message={
          <>
            {invoiceInfoModal.overdueInvoice.map((e) => (
              <FlexStart>
                <FieldName>
                  {months[e.referenceMonth]} / {e.referenceYear}
                </FieldName>
                <FieldValue>
                  {e.value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </FieldValue>
              </FlexStart>
            ))}
          </>
        }
      />
    </MainContainer>
  );
};

export default Dashboard;
